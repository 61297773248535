import { StakeholderProfileMap } from "@flightpath/coreui/dist/widgets/stakeholderProfileMap/StakeholderProfileMap_view";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { Checkbox } from "../../../../../../../components/ui/Checkbox";
import ColorScale from "../../../../../../../core/colorScale/ColorScale";
import I18n from "../../../../../../../core/localization/I18n";
import { Enums } from "../../../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { HeatMapReportingPie } from "../../../../Stakeholders/StakeholderReporting/components/StakeholderPieCells/StakeHolderHeatMap_ReportingPie";
import { JoinOperators } from "../../../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterStoreAddon";

const _checkboxOptions = [
  { key: "advocate", label: "Advocate", value: "advocate", isChecked: true },
  { key: "neutral", label: "Neutral", value: "neutral", isChecked: true },
  {
    key: "concerned",
    label: "Concerned",
    value: "concerned",
    isChecked: true
  },
  { key: "unknown", label: "Unknown", value: "unknown", isChecked: true }
];
const _colorScheme = {
  all: ["#f8f8f8", "#3d00c1"],
  advocate: ["#f8f8f8", "#1AC541"],
  concerned: ["#f8f8f8", "#E00606"],
  neutral: ["#f8f8f8", "#2AE1EE"],
  unknown: ["#f8f8f8", "#999999"]
};

export const AudienceHeatmap: React.FC<{
  data: FP.Entities.IAudience[];
  profilingType: "0" | "awareness" | "understanding" | "capability" | "commitment" | "adoption";
  stakeholderProfileSelector?: JSX.Element;
}> = ({ data, profilingType, stakeholderProfileSelector }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(profilingType === "0" ? "all" : "concerned");
  useEffect(() => {
    setFilter(profilingType === "0" ? "all" : "concerned");
  }, [profilingType]);
  const [checkboxOptions, setCheckboxOptions] = useState(_checkboxOptions);

  const handleCheckboxChange = (value: string, isChecked: boolean) => {
    const updatedOptions = checkboxOptions.map(option => {
      if (option.value === value) {
        return { ...option, isChecked };
      }
      return option;
    });
    setCheckboxOptions(updatedOptions);
  };
  const allSelected = checkboxOptions.every(option => option.isChecked);
  const selectedValues = checkboxOptions.filter(option => option.isChecked).map(option => option.value);

  const stakeholders = useMemo(
    () =>
      _.groupBy(
        data.filter(e => {
          return (
            e.size > 0 &&
            e.impact > 0 &&
            (allSelected ||
              (selectedValues.includes("advocate") && e[profilingType] <= 5 && e[profilingType] > 3.5) ||
              (selectedValues.includes("neutral") && e[profilingType] <= 3.5 && e[profilingType] >= 2.5) ||
              (selectedValues.includes("concerned") && e[profilingType] < 2.5 && e[profilingType] > 0) ||
              (selectedValues.includes("unknown") && e[profilingType] <= 0))
          );
        }),
        // xAxis is first param so map impact first and the yAxis size
        e => `${e.impact}-${e.size}`
      ),
    [data, profilingType, selectedValues, allSelected]
  );

  const maxVal = useMemo(
    () =>
      _.maxBy(
        _.map(stakeholders, e => e),
        e => e.length
      )?.length || 0,
    [stakeholders]
  );

  const colourScale = new ColorScale(0, maxVal === 0 ? maxVal + 1 : maxVal, _colorScheme[filter], 1);

  const handlePieSegmentClick = e => {
    navigate(e.data.link);
  };

  return (
    <div className="stakeholder-heatmap">
      <div className="stakeholder-heatmap__headings">
        {stakeholderProfileSelector}
        {profilingType !== "0" && (
          <div className="stakeholder-heatmap__checkboxes">
            {checkboxOptions.map(option => (
              <>
                <Checkbox
                  key={option.key}
                  id={option.key + "cb"}
                  className="ml-3"
                  checked={option.isChecked}
                  onChange={e => handleCheckboxChange(option.value, e.currentTarget.checked)}
                  style={{ accentColor: _colorScheme[option.value][1] }}
                />
                <label htmlFor={option.key + "cb"}>
                  <h4 className="d-inline-block">{option.label}</h4>
                </label>
              </>
            ))}
          </div>
        )}
      </div>
      <StakeholderProfileMap
        labels={{
          topLeft: I18n.t("visualisations.keepSatisfied"),
          topRight: I18n.t("visualisations.manageClosely"),
          bottomLeft: I18n.t("visualisations.monitor"),
          bottomRight: I18n.t("visualisations.keepInformed"),
          xAxis: I18n.t("visualisations.levelOfImpact"),
          yAxis: I18n.t("grids.size")
        }}
        getCellContent={(x: number, y: number) => {
          let item = stakeholders[`${x}-${y}`];
          // Calculate pie data for each item
          const colors = ["#F91E1E", "#2AE1EE", "#1AC541", "#DEDEDE"];

          const itemConcerned = item ? item.filter(e => e[profilingType] < 2.5 && e[profilingType] > 0).length : 0;
          const itemNeutral = item ? item.filter(e => e[profilingType] <= 3.5 && e[profilingType] >= 2.5).length : 0;
          const itemAdvocate = item ? item.filter(e => e[profilingType] <= 5 && e[profilingType] > 3.5).length : 0;
          const itemUnknown = item ? item.filter(e => e[profilingType] <= 0).length : 0;

          const generateLink = (label, impact, size, profilingType) => {
            const linkLabel = label.toLowerCase();
            return `/organisations/${organisationId}/projects/${projectId}/audiences/${GetLinkUrl(
              impact,
              size,
              profilingType,
              linkLabel
            )}`;
          };

          const itemPieData = [
            {
              label: I18n.t("visualisations.concerned"),
              value: itemConcerned,
              id: "concerned",
              link: itemConcerned > 0 && generateLink("concerned", item[0].impact, item[0].size, profilingType)
            },
            {
              label: I18n.t("visualisations.neutral"),
              value: itemNeutral,
              id: "neutral",
              link: itemNeutral > 0 && generateLink("neutral", item[0].impact, item[0].size, profilingType)
            },
            {
              label: I18n.t("visualisations.advocate"),
              value: itemAdvocate,
              id: "advocate",
              link: itemAdvocate > 0 && generateLink("advocate", item[0].impact, item[0].size, profilingType)
            },
            {
              label: I18n.t("visualisations.Unknown"),
              value: itemUnknown,
              id: "Unknown",
              link: itemUnknown > 0 && generateLink("unknown", item[0].impact, item[0].size, profilingType)
            }
          ];

          return profilingType === "0" ? (
            <Tooltip
              theme="light"
              followCursor
              html={
                <div className="stakeholder-heatmap__tooltip">
                  <h4>{I18n.get("phrases.audienceName")}</h4>
                  <div className="d-flex flex-column align-items-start">{item && item.map(e => <p>{e.name}</p>)}</div>
                </div>
              }
              disabled={!item}
            >
              <Link
                to={`/organisations/${organisationId}/projects/${projectId}/audiences/${GetLinkUrl(
                  x,
                  y,
                  profilingType,
                  filter
                )}`}
                className="stakeholder-heatmap__cell"
                style={{
                  backgroundColor: item ? colourScale.getColor(item.length).toHexString() : "#f8f8f8",
                  color: item && (colourScale.getColor(item.length).isDarkColor() ? "#fff" : "#000")
                }}
              >
                {item ? item.length : ""}
              </Link>
            </Tooltip>
          ) : (
            <>
              {item ? (
                <HeatMapReportingPie
                  data={itemPieData}
                  metric={item.length}
                  colourArray={colors}
                  onClick={handlePieSegmentClick}
                />
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </div>
  );
};

const GetLinkUrl = (impact, size, profilingType, filter) => {
  const impactL = encodeURIComponent(`${impact} - ${Enums.Translator.ImpactLevel(impact)}`.trim());
  const sizeL = encodeURIComponent(`${size}`.trim());

  let res = `?impact=${impactL}&size=@${sizeL}`;
  if (filter !== "all") {
    res += `&${JoinOperators.or.symbol}=${profilingType}|${GetFilterValues(filter)}`;
  }
  return res;
};

const GetFilterValues = filter => {
  const s = {
    advocate: "Advocate",
    concerned: "Concerned|@Cautious",
    neutral: "Neutral",
    unknown: "Unknown"
  };
  return s[filter];
};
