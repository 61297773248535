import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { StakeholderCapacityModel } from "./StakeholderCapacity_model";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { GridView } from "../../../../../../core/grids/GridView_view";
import {
  AudienceCapacityGrid_GridModel,
  StakeholderCapacityGrid_GridModel
} from "./StakeholderCapacityGrid/StakeholderCapacityGrid_GridModel";
import { Input } from "../../../../../../components/ui/_forms/Input";
import { Enums, UiSizes } from "../../../../../../enums";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { useFlightPathUser } from "../../../../../../core/auth_insight/UserContext";
import { StakeholderCapacityChart } from "./StakeholderCapacityChart/StakeholderCapacityChart_view";
import I18n from "../../../../../../core/localization/I18n";
import { Panel } from "../../../../../../components/ui/Panel";
import "./_stakeholder-capacity.scss";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";

interface Props {
  stakeholderType: number;
}

export const StakeholderCapacity: React.FC<Props> = observer(props => {
  const authUser = useFlightPathUser();
  const isFirstRender = useRef(true);
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(
    () => new StakeholderCapacityModel(organisationId, authUser, props.stakeholderType)
  );

  const [gridModel] = useState(() =>
    props.stakeholderType === Enums.StakeholderType.INDIVIDUAL
      ? new StakeholderCapacityGrid_GridModel(organisationId)
      : new AudienceCapacityGrid_GridModel(organisationId)
  );
  useEffect(() => {
    if (isFirstRender.current) {
      model.onMount();
      isFirstRender.current = false;
    }
    return () => {
      if (model.isComponentMounted) {
        model.onUnmount();
      }
    };
  }, [model]);

  useEffect(() => {
    if (organisationId !== model.organisationId || props.stakeholderType !== model.stakeholderType) {
      setModel(new StakeholderCapacityModel(organisationId, authUser, props.stakeholderType));
    }
  }, [model, organisationId, props.stakeholderType, authUser]);

  return (
    <div className="capacity-view-wrapper col">
      <Panel.Panel
        className={`py-2 px-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        {model.isLoading ? (
          <PositionedSpinner></PositionedSpinner>
        ) : (
          <StakeholderCapacityChart
            data={model.stakeholderAssignmentMetrics || {}}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${
                  props.stakeholderType === Enums.StakeholderType.AUDIENCE
                    ? item.indexValue.split("-").slice(1)[0]
                    : item.indexValue.split("-").slice(1).join("")
                }`;
              },
              indexBy: e => {
                return `${e.id} - ${e.name}`;
              },
              keys: ["impactCount", "actionCount"],
              colors: ["#19d3e0", "#faa082", "#ffc036"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: "Total count",
              groupType: "grouped",
              chartMargin: { bottom: 80 },
              bottomAxisValueFormat: (val: string) => ({
                value: `${
                  props.stakeholderType === Enums.StakeholderType.AUDIENCE
                    ? val.split("-").slice(1)[0]
                    : val.split("-").slice(1).join("")
                }`,
                maxLineLength: 18,
                maxLines: 3,
                truncateAtChar: 15
              }),
              bottomAxisProps: { legendOffset: 70 },
              enableGridX: true
            })}
          ></StakeholderCapacityChart>
        )}
      </Panel.Panel>
      <Panel.Panel
        className={`capacity-grid-wrapper p-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <div className="row align-items-center pb-2">
          <div className="col d-flex">
            <div className="col-10"></div>
            <div className="col-2 p-0">
              <div className="text-right">
                <Input
                  autoFocus={true}
                  size={UiSizes.XS}
                  onChange={model.setSearchText}
                  placeholder="Search"
                  icon={IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  style={{ height: 32 }}
                />
              </div>
            </div>
          </div>
        </div>
        <GridView
          data={model.stakeholderAssignmentMetrics}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onCellClicked={function (cell: string, isEditMode?: boolean): void {}}
          onCellEditModeChange={function (cell: string, isEditMode: boolean): void {}}
          onSelectionChanged={model.updateData}
          users={[]}
          overlayNoRowsTemplate={`<div></div>`}
          isRowSelectableFn={model.isRowSelectable}
          onGridReady={model.onGridReady}
        />
      </Panel.Panel>
    </div>
  );
});
